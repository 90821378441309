
import { defineComponent, onMounted, provide, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import TabMenu from 'primevue/tabmenu'
import ModuleHeader from '@/components/commons/boxes/ModuleHeader.vue'
import LoadingScreen from '@/components/commons/LoadingScreen.vue'
import menuItems from './menu-items'
import { findPersonById } from '@/api/people.api'
import { Person } from '@/models/person/classes/Person'
import { PersonRoles, PlatformUserRoleType } from '@/models/person/enums'
import { PersonKey } from './keys'
export default defineComponent({
  components: {
    ModuleHeader,
    TabMenu,
    LoadingScreen
  },
  setup () {
    const { params } = useRoute()
    const personId = params.personId as string
    const isLoading = ref(true)
    const person = ref<Person|null>(null)
    const isStaff = computed<boolean>(() => person.value?.rol === PlatformUserRoleType.Staff)
    provide(PersonKey, person)
    onMounted(async () => {
      try {
        isLoading.value = true
        person.value = await findPersonById(personId)
      } catch (error) {
        console.log(error)
      } finally {
        isLoading.value = false
      }
    })
    return {
      menuItems,
      person,
      isLoading,
      getRole: (rol:PersonRoles) => Person.parsedRole(rol),
      isStaff
    }
  }
})
