import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "p-d-inline-flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingScreen = _resolveComponent("LoadingScreen")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_ModuleHeader = _resolveComponent("ModuleHeader")!
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_LoadingScreen, { key: 0 }))
    : (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ModuleHeader, null, {
            title: _withCtx(() => [
              (_ctx.person.fullName)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.person.fullName), 1))
                : _createCommentVNode("", true),
              (_ctx.isStaff && _ctx.person.roles)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.person.roles, (rol) => {
                      return (_openBlock(), _createBlock(_component_Tag, {
                        key: rol,
                        class: "p-ml-2 p-mr-2 bg-gray-400 text-black font-weight-500 p-pl-3 p-pr-3 p-pt-2 p-pb-2",
                        style: {"border-radius":"4px"},
                        value: _ctx.getRole(rol)
                      }, null, 8, ["value"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_TabMenu, {
            class: "ch-tabs",
            model: _ctx.menuItems
          }, null, 8, ["model"]),
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
              (_openBlock(), _createBlock(_KeepAlive, null, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  key: _ctx.$route.fullPath
                }))
              ], 1024))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
}