import { MenuItem } from 'primevue/menuitem'
import { EducationRouteNames } from '@/router/route-names'

export default <MenuItem[]>[
  {
    label: 'General',
    to: {
      replace: true,
      name: EducationRouteNames.PERSON_PROFILE_GENERAL
    }
  },
  {
    label: 'Historial',
    to: {
      replace: true,
      name: EducationRouteNames.PERSON_PROFILE_HISTORY
    }
  },
  {
    label: 'Perfil profesional',
    to: {
      replace: true,
      name: EducationRouteNames.PERSON_PROFILE_PROFESSIONAL
    }
  },
  {
    label: 'Registro de actividad',
    to: {
      replace: true,
      name: EducationRouteNames.PERSON_PROFILE_ACTIVITY
    }
  }
]
